<template>
    <div>
        <a-collapse @change="fetchAnswer" accordion>
            <a-collapse-panel
           @change="testComponent" v-for="historyData in eddieUserHistory" :key="historyData.id"
            :header="historyData.question">

              <p v-if="!historyData.answer">Loading...</p>
              <p v-else>{{historyData.answer}}</p>

            </a-collapse-panel>
      </a-collapse>
    </div>
</template>

<script>
/**
 * Used Design
 * https://1x.antdv.com/components/collapse/#Accordion
 */
export default {
  data() {
    return {
      eddieHistoryData: [],
    };
  },
  async mounted() {
    await this.$store.dispatch('eddieHistory');
  },

  computed: {
    eddieUserHistory() {
      return this.$store.state.eddie_history.data;
    },
  },

  methods: {
    hashTagString(id) {
      return `#eddie_${id}`;
    },
    tagString(id) {
      return `eddie_${id}`;
    },
    fetchAnswer(id) {
      if (!this.eddieUserHistory.filter((i) => i.id === id)[0].answer) {
        this.$store.dispatch('updateAnswer', id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
