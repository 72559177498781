import { render, staticRenderFns } from "./HistoryWidget.vue?vue&type=template&id=9c2732ac&scoped=true"
import script from "./HistoryWidget.vue?vue&type=script&lang=js"
export * from "./HistoryWidget.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c2732ac",
  null
  
)

export default component.exports