<template>
  <div id="eddie_box">
    <!-- <div><span id="eddie_title">Eddie</span><br /></div> -->
    <!-- Context, Prompt, and ask button -->
    <div v-if="openAIKey && !showHistory">
      <div>
        <div>
          <div class="d-flex justify-content-between align-items-center flex-wrap mt-3">
            <h5 class="eddie__heading">What's going on in your mind?</h5>
            <button
              @click="queryChat"
              class="chat-btn ed-btn ed-btn__sm ed-btn__blim"
              :disabled="isLoading"
            >
              <span v-if="!isLoading">Ask</span>
              <span
                v-else
                class="spinner-border spinner-border-sm ml-2"
                :style="{ opacity: isLoading ? 1 : 0 }"
              ></span>
            </button>
          </div>
          <textarea
            v-model="prompt"
            placeholder="Ask me Something?"
            ref="eddie-editor"
            class="form-control mt-3"
          ></textarea>
        </div>
      </div>
      <div id="result">
        <div v-html="prompt"></div>
        <div v-html="openAiResult.response"></div>
      </div>
    </div>
    <!-- Eddie's Preferences -->
    <div
      v-if="openAIKey"
      class="d-flex align-items-center justify-content-between mt-1"
    >
      <!-- <div>
        <ed-dropdown
          :selected="selectedModelLabel"
          :listItems="models"
          :mapFunction="(model) => model.label"
          :isSelected="handleModelSelect"
          customClass="ed-dropdown__sm"
        />
      </div> -->
      <div class="d-flex align-items-center">
        <i
          title="Clear context"
          class="bx bx-brush-alt mx-2 d-flex box-icon"
          v-if="!clearContextLoading"
          @click="clearContext"
        ></i>
        <span
          v-else
          class="spinner-border spinner-border-sm ml-2"
          :style="{ opacity: clearContextLoading ? 1 : 0 }"
        ></span>
      </div>
      <div class="d-flex align-items-center">
        <i
          :title="showHistory ? 'Hide History' : 'Show History'"
          class="bx bx-history mx-2 d-flex box-icon"
          v-if="!clearContextLoading"
          @click="toggleShowHistory"
        ></i>
      </div>
      <!-- <div>
        <select
          class="form-select"
          v-model="maxWords"
          aria-label="Default select example"
        >
          <option
            v-for="maxWord in maxWordsList"
            :key="maxWord"
            :value="maxWord"
          >
            {{ maxWord }}
          </option>
        </select>
      </div> -->
    </div>
    <div v-if="openAIKey && showHistory">
        <EddieHistory/>
    </div>
    <!-- Add API key for Using Eddie Widget -->
    <div v-if="!openAIKey" class="eddie-widget-api p-2">
      <label for="" class="ed-label">Eddie Key <a target="_blank" href="https://beta.openai.com/login">(Open AI Key)</a></label>
      <ed-input :onChange="setOpenAiKey" placeholder="Enter OpenAI Key" />
      <div class="mt-2">
        <button v-if="!submitApiKeyLoading" @click="submitApiKey()" class="ed-btn__blim">Submit</button>
      <span
        class="spinner-border ml-2"
        :style="{ opacity: submitApiKeyLoading ? 1 : 0 }"
      ></span>
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked';
import { mapActions, mapGetters } from 'vuex';
import EddieHistory from '@/common/widgets/EddieWidget/HistoryWidget.vue';
import edInput from '@/common/components/ed-forms/ed-input.vue';

export default {
  data() {
    return {
      // isLoading: false,
      clearContextLoading: false,
      submitApiKeyLoading: false,
      maxWords: 300,
      prompt: '',
      result: '😴😴😴😴',
      selectedModel: 'text-davinci-003',
      selectedModelLabel: 'Davinci(Advance)',
      isWritingFirstTime: true,
      openAiKey: '',
      models: [
        {
          id: 'text-babbage-001',
          label: 'Babbage(Light)',
        },
        {
          id: 'text-curie-001',
          label: 'Curie(Medium)',
        },
        {
          id: 'text-davinci-003',
          label: 'Davinci(Advance)',
        },
      ],
      maxWordsList: [100, 300, 500, 1000, 1500],
    };
  },

  components: {
    // edDropdown,
    EddieHistory,
    edInput,
  },

  computed: {
    isLoading() {
      return this.$store.state.openai_meta.is_searching;
    },
    showHistory() {
      return this.$store.state.openai_meta.showHistory;
    },
    ...mapGetters({
      openAIKey: 'auth/openAIKey',
    }),
    markedResult() {
      return marked(this.result);
    },

    openAiResult() {
      return this.$store.state.eddie_result;
    },
  },

  watch: {
    selectedModel(val) {
      localStorage.setItem('currentModel', val);
    },
    openAiResult(val) {
      this.result = `**Q:** ${val.prompt}\n\n**A:** ${val.choices[0].text}`;
    },
    prompt() {
      if (this.isWritingFirstTime) {
        this.result = '😃😃😃😃';
        if (this.prompt.trim() === '') {
          setTimeout(() => {
            this.result = '😴😴😴😴';
          }, 1000);
        }
      }
    },
    maxWords(val) {
      localStorage.setItem('currentModelMaxWord', val);
    },
  },

  async created() {
    await this.$highway.singleDispatcher('searchFromOpenAi');
  },

  mounted() {
    this.selectedModel = localStorage.getItem('currentModel')
      ? localStorage.getItem('currentModel')
      : 'text-davinci-003';
    this.maxWords = localStorage.getItem('currentModelMaxWord')
      ? localStorage.getItem('currentModelMaxWord')
      : 300;

    if (this.openAiResult) {
      this.result = `**Q:** ${this.openAiResult.prompt}\n\n**A:** ${this.openAiResult.choices[0].text}`;
    }

    const eddieEditor = this.$refs['eddie-editor'];
    eddieEditor.addEventListener('keydown', (e) => {
      const keyCode = e.which || e.keyCode;

      // 13 represents the Enter key
      if (keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        this.queryChat();
      }
    });
  },

  methods: {
    ...mapActions({
      searchFromOpenAi: 'searchFromOpenAi',
      updateProfile: 'auth/updateProfile',
    }),
    clearContext() {
      this.clearContextLoading = true;
      this.$store.dispatch('clearContext').then(() => {
        this.clearContextLoading = false;
      });
    },
    queryChat() {
      // this.isLoading = true;
      this.result = `**Q:** ${this.prompt}\n\n**A:** `;
      this.searchFromOpenAi({
        maxWords: this.maxWords,
        prompt: this.prompt,
        model: this.selectedModel,
      })
        .then(() => {
          this.result = `**Q:** ${this.prompt}\n\n**A:** ${this.$store.state.eddie_result.response}`;
          this.prompt = '';
          // this.isLoading = false;
        })
        .catch((err) => {
          // this.isLoading = false;
          this.result = err;
        });
      this.isWritingFirstTime = false;
    },
    handleModelSelect(newModel) {
      const selectedModel = this.models.filter(
        (model) => model.label === newModel,
      );
      if (selectedModel.length > 0) {
        this.selectedModel = selectedModel[0].id;
        this.selectedModelLabel = newModel;
      }
    },
    handleMaxWords(newMaxWord) {
      this.maxWords = newMaxWord;
    },
    setOpenAiKey(val) {
      this.openAiKey = val;
    },
    async submitApiKey() {
      this.submitApiKeyLoading = true;
      const profilePayload = {
        apiKey: this.openAiKey,
      };

      const fd = new FormData();
      Object.keys(profilePayload).forEach((key) => {
        fd.append(`${key}`, profilePayload[key]);
      });

      await this.updateProfile(fd).then(() => {
        this.submitApiKeyLoading = false;
      });
    },
    toggleShowHistory() {
      this.$store.dispatch('toggleShowHistoryInOpenAI');
    },
  },
};
</script>

<style lang="scss" scoped>
#eddie_box {
  max-width: 700px;
  margin: 0 auto;
  min-height: 100vh;
}

#eddie_title {
  font-size: 15px;
  font-family: "Poppins", monospace;
  padding: 0;
  margin: 0;
}
sub {
  margin: 0;
  padding: 0;
}
#result {
  border: 0.0625rem solid rgb(192, 192, 192);
  padding: 0.625rem;
  margin: .625rem 0;
  border-radius: 0.3125em;
}

.eddie {
  &__heading {
    margin-bottom: 0;
  }
}
</style>
